<template>
    <v-container>
        
            <p class="text-center text-h5 mt-5">Thanks for your interest in Escuela de Lectura for Schools</p>
            <p class="text-center">Please fill out this form to request a quote</p>
            <v-form style="max-width: 900px;margin: auto;" ref="form" v-model="validForm" @submit.prevent="SendMail" lazy-validation >
            <v-row>
                <v-col >
                    <p class="text-h5 mt-3">Full Name *</p>
                </v-col>
                <v-col cols="8">
                    <v-text-field v-model="name" label="Full Name" solo :rules="nameRules" required></v-text-field>
                </v-col>
                       
            </v-row>
            <v-row>
                <v-col >
                    <p class="text-h5 mt-3">Your Role *</p>
                </v-col>
                <v-col cols="8">
                    
                    <v-select v-model="role" :items="items" label="Role" solo></v-select>
                </v-col> 
            </v-row>
            <v-row>
                <v-col >
                    <p class="text-h5 mt-3">Email *</p>
                </v-col>
                <v-col cols="8">
                    <v-text-field v-model="email" label="Email" solo :rules="emailRules"></v-text-field>
                </v-col> 
            </v-row>
            <v-row>
                <v-col >
                    <p class="text-h5 mt-3">Phone</p>
                </v-col>
                <v-col cols="8">
                    <v-text-field v-model="phone" label="Phone" solo></v-text-field>
                </v-col>  
            </v-row>
            <v-row>
                <v-col >
                    <p class="text-h5 mt-3">School Name</p>
                </v-col>
                <v-col cols="8">
                    <v-text-field v-model="SchoolName" label="Shool Name" solo></v-text-field>
                </v-col>  
            </v-row>
            <v-row v-if="errorMessage != ''">
                <v-alert dense outlined type="error" class="col-md-10">{{ errorMessage }}</v-alert>
            </v-row>
            <v-row v-if="successMessage != ''">
                <v-alert dense outlined type="success" class="col-md-10">{{ successMessage }}</v-alert>
            </v-row>
            <v-row justify="center">
                <v-col cols="4">
                    <v-btn class="ma-2 subtitle-1" color="light-blue accent-4" x-large type="submit">
                        Get a Quote!
                    </v-btn>
                </v-col>
                
            </v-row>
            
            </v-form>

    </v-container>
</template>

<script>
import { functions } from '../plugins/firebase'
export default {
    data() {
        return {
            name:'',
            role:'',
            items: ['Teacher', 'School Administrator', 'District Administrator', 'Other'],
            email:'',
            phone: '',
            SchoolName:'',
            validForm:false,
            nameRules: [
                v => (v && v.length >= 2) || 'Minimum length is 2 characters',
                v => (v && v.length <= 30) || 'Name must be less than 30 characters'
            ],
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            errorMessage: '',
            successMessage: ''
        }
    },
    methods: {
        SendMail(){
            if (!this.$refs.form.validate()) return;
            //console.log('asd');
            const sendContactForm = functions.httpsCallable('sendContactForm');
            sendContactForm({name: this.name, role: this.role, email: this.email, phone:this.phone, SchoolName: this.SchoolName }).then((result) => {
            console.log(result);
                this.reset();
                this.successMessage = 'Message sent successfully, we will contact you'
                setTimeout(() => this.successMessage = '', 10000);
            }).catch((error) => {
                this.errorMessage = 'An error occurred, try again later';
                setTimeout(() => this.errorMessage = '', 10000);
            // Getting the Error details.
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.log(error);
            });
        },
        reset () {
            this.$refs.form.reset()
        },
    },
    mounted(){
        
    }
}
</script>

<style>
</style>